import React from "react";
import Layout from "../components/layout";
import FAQPanel from "../components/faq-panel/faq-panel.component";
import { Link } from "gatsby";
import "../styles/app.scss";

import UsefulDocuments from "../components/useful-documents/useful-documents.component";
import ResourcesWrapper from "../components/resources/resources-wrapper.component";

export default function PlacingBusiness() {
  return (
    <Layout title="Placing Business">
      <section className="page-section row">
        <div className="col-xs-12 col-md-7 margin-bottom-double">
          <h1 className="page-title">Placing business</h1>
          <div className="blue-box">
            <p>
              <strong>Step-by-step guide to HSBC UK mortgages</strong>
            </p>
            <p>Read a quick overview of the key stages involved, from application to drawdown.</p>

            <Link
              to="/step-by-step-guide-to-hsbc-mortgages"
              className="general-btn general-btn--inline general-btn--white"
            >
              Find out more
            </Link>
          </div>
          <section className="splitted-section">
            <div className="col-xs-12  col-xl-7">
              <h2 className="page-title page-title--small text-center">
                <strong>Resource</strong>
              </h2>
              <ResourcesWrapper />
            </div>
            <div className="col-xs-12 col-xl-5">
              <h2 className="page-title page-title--small page-title--placing-business">
                <strong>Useful documents</strong>
              </h2>
              <section className="splitted-section">
                <UsefulDocuments />
              </section>
            </div>
          </section>
        </div>

        <div className="col-xs-12 col-md-4 col-lg-offset-1">
          <FAQPanel />
        </div>
      </section>
    </Layout>
  );
}
