import React from 'react';
import AnchorList from '../anchor-list/anchor-list.component';
import GeneralAnchor from '../general-anchor/general-anchor.component';
import { FAQ_QUESTIONS_SCHEME } from '../../data/faq.data';

const FAQPanel = ({ faqItems = FAQ_QUESTIONS_SCHEME, title = 'Frequently asked questions' }) => {
  return (
    <section className='faq-panel col'>
      <h2>
        <GeneralAnchor
          isBlank
          href='/frequently-asked-questions'
          rel='noreferrer'
          isLink={true}
          noClass={true}
        >
          {title}
        </GeneralAnchor>
      </h2>
      <AnchorList
        anchorItems={faqItems}
        additionalClassName='ul-ns'
      />
    </section>
  );
};

export default FAQPanel;